<template>
  <div class="WOMEN">
    <Nav />
    <div class="women">
      <!-- banner -->
      <div v-for="(item, index) in banner" :key="index" class="banner">
        <img :src="item.img" alt="" />
      </div>
      <div style="width: 75%; margin: 20px auto">
        <!-- threewomen -->
        <div class="threewomen">
          <div v-for="(item, index) in threewomen" :key="index">
            <img :src="item.img" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div>
        <div class="fourwomen">
          <div v-for="(item, index) in fourimg" :key="index" class="fourimg">
            <img :src="item.img" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="black">
      <p>Don't know which product suits your customer? Let us help!</p>
      <button
        type="button"
        class="btn-primary"
        data-toggle="modal"
        data-target="#Contactnow"
      >
        Contact now
      </button>
    </div>
    <!-- Modal 弹窗  -->
    <div
      class="modal fade"
      id="Contactnow"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-headers">
            <h4 class="modal-title" id="exampleModalLabel">
              Have a question? We're happy to help!
            </h4>
            <p>
              Leave a message on the form below or
              WhatsApp（+86-1509-2233-077）us. We will get back to you within 24
              hours!
            </p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- input框 -->
            <div class="CONTACT">
              <h2>INQUIRIES & QUESTIONS</h2>
              <div class="inputs">
                <input
                  type="text"
                  v-model="questionname"
                  id=""
                  placeholder="Name*"
                />
                <input
                  type="text"
                  name=""
                  id=""
                  v-model="questionemail"
                  placeholder="Email Address*"
                />
                <input
                  type="text"
                  name=""
                  id=""
                  v-model="questioncountry"
                  placeholder="Country*"
                  list="typelist"
                />
                <datalist id="typelist">
                  <option v-for="(item, index) in questcountry" :key="index">
                    {{ item.label }}
                  </option>
                </datalist>
                <input
                  type="text"
                  name=""
                  id=""
                  v-model="questionPhone"
                  placeholder="Phone/whatsapp*"
                />
                <input
                  type="text"
                  name=""
                  id=""
                  v-model="questionBusiness"
                  placeholder="Business name"
                />
                <input
                  type="text"
                  name=""
                  id=""
                  v-model="questionSelect"
                  list="inputlist"
                  placeholder="Select your business role..*"
                />
                <datalist id="inputlist">
                  <option
                    v-for="(item, index) in questioninputlists"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </datalist>
              </div>
              <textarea
                type=""
                placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
              ></textarea>

              <button @click="gethelp">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 75%; margin: 0 auto">
      <div class="oldwoman">
        <img src="../../assets/logoimgs/video.png" alt="" />
      </div>

      <div class="wig">
        <div class="womensmenu">
          <span
            @click="checkoutType(1)"
            :class="tabs === 1 ? 'typeActive' : ''"
          >
            <p>TOP SALES</p>
          </span>
          <span
            @click="checkoutType(2)"
            :class="tabs === 2 ? 'typeActive' : ''"
          >
            <p>NEW ARRIVALS</p>
          </span>
        </div>
        <!-- 选项卡切换 -->
        <div>
          <span v-if="tabs == 1">
            <div class="tabs1">
              <div
                v-for="(item, index) in wigboxs"
                :key="index"
                class="wigboxs"
              >
                <img :src="item.img" alt="" class="img1" />
                <img :src="item.imgs" alt="" class="img2" />
                <p>{{ item.content }}</p>
                <button @click="goview(item.id)">{{ item.but1 }}</button>
                <button>{{ item.but2 }}</button>
              </div>
            </div>
          </span>
          <span v-if="tabs == 2">
            <div class="tabs2">
              <div
                v-for="(item, index) in wigboxs2"
                :key="index"
                class="wigboxs"
              >
                <img :src="item.img" alt="" class="img1" />
                <img :src="item.imgs" alt="" class="img2" />
                <p>{{ item.content }}</p>
                <button>{{ item.but1 }}</button>
                <button>{{ item.but2 }}</button>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
    <!-- 40% -->
    <div v-for="(item, index) in beauful" :key="index">
      <router-link to="/">
        <img :src="item.img" alt="" style="width: 100%" />
      </router-link>
    </div>
    <div class="CUSTOMwig">
      <div v-for="(item, index) in custom" :key="index" class="CUSTO">
        <div class="left">
          <h3 style="color: #000; text-align: center">{{ item.h3 }}</h3>
          <p>{{ item.content }}</p>
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#CUSTOMmodel"
          >
            {{ item.button }}
          </button>
        </div>
        <div class="right">
          <img :src="item.img" alt="" />
        </div>
      </div>
      <!-- CUSTOMmodel button Modal -->
      <div
        class="modal fade"
        id="CUSTOMmodel"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div>
                <h4
                  class="modal-title"
                  id="exampleModalLabel"
                  style="width: 100%"
                >
                  Customize your wigs
                </h4>
                <div>
                  In order for us to give you an accurate quote, please briefly
                  tell us the following information. If you do not have the
                  information with you right now, no worries, we will contact
                  you and offer you the right assistance upon your submission of
                  the form.
                </div>
              </div>

              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- input框 -->
              <div class="CONTACT">
                <div class="inputs">
                  <input
                    type="text"
                    v-model="Names"
                    id=""
                    placeholder="Name*"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="Emails"
                    placeholder="Email Address*"
                  />
                  <input
                    type="text"
                    name=""
                    v-model="Countryss"
                    id=""
                    placeholder="Country*"
                    list="typelist"
                  />
                  <datalist id="typelist">
                    <option v-for="(item, index) in country" :key="index">
                      {{ item.label }}
                    </option>
                  </datalist>
                  <input
                    type="text"
                    v-model="whatsapp"
                    name=""
                    id=""
                    placeholder="Phone/whatsapp*"
                  />
                  <input
                    type="text"
                    name=""
                    v-model="Businenamess"
                    id=""
                    placeholder="Business name"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="Selectrole"
                    list="inputlist"
                    placeholder="Select your business role..*"
                  />
                  <input
                    type="text"
                    name=""
                    v-model="BaseSize"
                    id=""
                    placeholder="Base Type and Size"
                  />
                  <input
                    type="text"
                    name=""
                    v-model="ColorLength"
                    id=""
                    placeholder="Hair Color/Length/Density/curl"
                  />
                  <datalist id="inputlist">
                    <option v-for="(item, index) in inputlists" :key="index">
                      {{ item.name }}
                    </option>
                  </datalist>
                </div>
                <textarea
                  type=""
                  placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
                ></textarea>
                <button @click="customsubmit()">Submit</button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="CHINA">
      <div class="CHINA_content">
        <div v-for="(item, index) in china" :key="index" class="chinafont">
          <h3>{{ item.h3 }}</h3>
          <p>{{ item.name }}</p>
        </div>
        <div class="chinaimg">
          <div v-for="(item, index) in chinaimg" :key="index" class="chinas">
            <img :src="item.img" alt="" />
            <div class="colors">
              <div v-for="(item, index) in clores" :key="index">
                <h3>{{ item.h3 }}</h3>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Whyjoins">
      <div v-for="(item, index) in Whyjoin" :key="index" class="div1">
        <h3>{{ item.h3 }}</h3>
        <h3 style="color: #f29d35">{{ item.h32 }}</h3>
        <h3>{{ item.h33 }}</h3>
        <h3>{{ item.h34 }}</h3>
        <h3>{{ item.h35 }}</h3>
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#Whyjoins"
        >
          {{ item.button }}
        </button>
      </div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="Whyjoins"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog dialog-lg">
          <div class="modal-content">
            <div class="modal-header">
              <div>
                <h3
                  class="modal-title"
                  style="text-align: center"
                  id="exampleModalLabel"
                >
                  Become an exclusive wholesaler
                </h3>
                <p>
                  Want to shop and receive special pricing on our one-of-a-kind
                  hair replacement products? Please fill the wholesale
                  application form below. You will be provided access to
                  exclusive wholesale pricing once information is verified and
                  account is approved.
                </p>
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- input框 -->
              <div class="CONTACT">
                <div class="inputs">
                  <input
                    type="text"
                    v-model="WhyjoinsNames"
                    id=""
                    placeholder="Name*"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    v-model="WhyjoinsEmails"
                    placeholder="Email Address*"
                  />
                  <input
                    type="text"
                    name=""
                    v-model="WhyjoinsCountryss"
                    id=""
                    placeholder="Country*"
                    list="typelist"
                  />
                  <datalist id="typelist">
                    <option
                      v-for="(item, index) in Whyjoinscountry"
                      :key="index"
                    >
                      {{ item.label }}
                    </option>
                  </datalist>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Phone/whatsapp*"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Business name"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    list="inputlist"
                    placeholder="Select your business role..*"
                  />
                  <datalist id="inputlist">
                    <option
                      v-for="(item, index) in Whyjoininputlists"
                      :key="index"
                    >
                      {{ item.name }}
                    </option>
                  </datalist>
                </div>
                <textarea
                  type=""
                  placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
                ></textarea>
                <button @click="Whyjoinsubmit()">Submit</button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- WHOLESALE CUSTOMER FEEDBACK 轮播 -->
    <div style="background-color: #f7f7f7; width: 100%">
      <div class="WHOLESALE" style="padding: 50px 0">
        <h2>WHOLESALE CUSTOMER FEEDBACK</h2>
        <p>Hear voices and feedback from New Times Hair real customers.</p>
        <div>
          <el-carousel :interval="5000" arrow="always" style="height: 650px">
            <el-carousel-item
              style="height: 650px"
              v-for="(item, index) in listphoto"
              :key="index"
              class="alwasing"
            >
              <div class="always">
                <div class="always_box">
                  <img :src="item.img" alt="" />
                  <p>{{ item.content }}</p>
                  <p>{{ item.name }}</p>
                </div>
              </div>
              <div class="always">
                <div class="always_box">
                  <img :src="item.imgs" alt="" />
                  <p>{{ item.content }}</p>
                  <p>{{ item.name }}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="BECOMES">
      <div class="Become">
        <div
          class="Become_dis"
          v-for="(item, index) in Become_dis"
          :key="index"
        >
          <img :src="item.img1" class="img1" />
          <div class="Become_bg">
            <h2>{{ item.dish2 }}</h2>
            <ul>
              <li
                v-for="(item, index) in listli"
                :key="index"
                style="opacity: 1"
              >
                {{ item.li }}
              </li>
            </ul>
            <p style="opacity: 1">{{ item.p1 }}</p>
            <p style="text-align: center; font-weight: 600">{{ item.p2 }}</p>
            <button>{{ item.button }}</button>
          </div>
        </div>
      </div>
      <div class="Become">
        <div class="Become_dis" v-for="(item, index) in dis2" :key="index">
          <img :src="item.img2" class="img1" />
          <div class="Become_bg">
            <h2>Join us as an exclusive wholesaler</h2>
            <p
              style="
                text-align: center;
                font-weight: 600;
                color: #f29d35;
                font-size: 22px;
              "
            >
              Why join?
            </p>
            <ul>
              <li
                style="font-weight: 600; opacity: 0.9; font-size: 20px"
                v-for="(item, index) in list"
                :key="index"
              >
                {{ item.li }}
              </li>
            </ul>
            <p style="opacity: 1; width: 80%">
              And most importantly – be a part of a supportive and uplifting
              community of hair replacement specialists worldwide!
            </p>

            <button>Apply now</button>
          </div>
        </div>
      </div>
    </div>
    <div class="FAQ" style="width: 75%">
      <h2>FAQ</h2>
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What are the differences between a toupee and a wig?
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              With more and more people choosing non-surgical hair loss
              solutions, the men’s hair piece wholesale market has seen a
              significant increase in the types of stock hairpieces that are
              designed to cater to people’s needs more precisely and
              specifically. Therefore, many terms in the male hairpiece world
              often get mistaken for one another. The most common pair of terms
              that people confuse with one another is “toupee” and “wig.”<br />

              Both toupee and wig products are supposed to be worn on people’s
              heads for the purpose of hiding hair loss, but there are quite a
              few ways in which they differ from one another. A toupee is
              usually a small hair piece made of real human hair or synthetic
              hair that is worn at the top of the wearer’s scalp to cover a bald
              area, while a wig is meant to offer complete coverage of the whole
              head. That is why toupees are typically a lot smaller than wigs.
              Due to the differences in hair loss patterns between men and
              women, toupees are more commonly used by men to cover the top area
              of the head, which is more prone to baldness, while wigs are more
              popular among women who experience hair thinning problems.<br />

              Generally, it takes a lot more hair material and hair knotting
              work to produce a wig than it does for a toupee. Therefore, men’s
              professional wigs are undoubtedly more expensive. However, wigs
              are commonly seen as a more user-friendly option in the sense that
              you do not need to shave your head to attach one because tape and
              glue are not necessary to secure a wig to your head.<br />

              One other thing to note is that more and more people, including
              those without hair loss problems, are choosing to wear wigs for
              fashion purposes. Ultimately, whether you should buy a wig or a
              toupee for men largely depends on your hair loss pattern.
              Wholesale men’s toupees are more of an ideal option for most types
              of partial loss, and wholesale men’s wigs are typically
              recommended for those with general hair thinning problems or
              complete baldness.
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                What is the best men's stock hair systems wholesale?
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              Some placeholder content for the second accordion panel. This
              panel is hidden by default.
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How canl get the right measurements for my clients?
              </button>
            </h2>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              And lastly, the placeholder content for the third and final
              accordion panel. This panel is hidden by default.
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFOUR">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFOUR"
                aria-expanded="false"
                aria-controls="collapseFOUR"
              >
                How canl get the right measurements for my clients?
              </button>
            </h2>
          </div>
          <div
            id="collapseFOUR"
            class="collapse"
            aria-labelledby="headingFOUR"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              How should l install a toupee for my clients?
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFive">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Why should l purchase men's stock hair systems wholesale from
                New Times Hair?
              </button>
            </h2>
          </div>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingFive"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              And lastly, the placeholder content for the third and final
              accordion panel. This panel is hidden by default.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- input框 -->
    <div style="width: 100%; background-color: #f2f2f2">
      <!-- input框 -->
      <div class="CONTACT">
        <h2>INQUIRIES & QUESTIONS</h2>
        <div class="inputs">
          <!-- <input id="type" type="text" placeholder="请选择"> -->

          <input type="text" v-model="one" id="" placeholder="Name*" />
          <input type="text" name="" id="" placeholder="Email Address*" />
          <input
            type="text"
            name=""
            id=""
            placeholder="Country*"
            list="typelist"
          />
          <datalist id="typelist">
            　　
            <option v-for="(item, index) in country" :key="index">
              {{ item.name }}
            </option>
          </datalist>
          <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
          <input type="text" name="" id="" placeholder="Business name" />
          <input
            type="text"
            name=""
            id=""
            list="inputlist"
            placeholder="Select your business role..*"
          />
          <datalist id="inputlist">
            　　
            <option v-for="(item, index) in inputlists" :key="index">
              {{ item.name }}
            </option>
          </datalist>
        </div>
        <textarea
          type=""
          placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
        ></textarea>
        <button @click="submit">Submit</button>
      </div>
      <!-- input框 -->
      <div class="CONTACTs">
        <h2>INQUIRIES & QUESTIONS</h2>
        <div class="inputs">
          <input type="text" v-model="one" id="" placeholder="Name*" />
          <input type="text" name="" id="" placeholder="Email Address*" />
          <input
            type="text"
            name=""
            id=""
            placeholder="Country*"
            list="typelist"
          />
          <datalist id="typelist">
            　　
            <option v-for="(item, index) in country" :key="index">
              {{ item.name }}
            </option>
          </datalist>
          <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
          <input type="text" name="" id="" placeholder="Business name" />
          <input
            type="text"
            name=""
            id=""
            list="inputlist"
            placeholder="Select your business role..*"
          />
          <datalist id="inputlist">
            　　
            <option v-for="(item, index) in inputlists" :key="index">
              {{ item.name }}
            </option>
          </datalist>
        </div>
        <textarea
          type=""
          placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
        ></textarea>
        <button @click="submits">Submit</button>
      </div>
    </div>
    <div class="top" @click="toTop">
      <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
    <div class="tops" @click="toTop">
      <img src="../../../src/assets/index/xiala_un@2x.png" alt="" />
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../components/Footer.vue";
import Nav from "../../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      one: "",
      Names: "",
      Emails: "",
      Countryss: "",
      Countrys: "",
      WhyjoinsNames: "",
      WhyjoinsEmails: "",
      WhyjoinsCountryss: "",

      questionname: "",
      questioncountry: "",
      questionemail: "",
      questionPhone: "",
      questionBusiness: "",
      questionSelect: "",
      ColorLength: "",
      BaseSize: "",
      Selectrole: "",
      Businenamess: "",
      whatsapp: "",
      tabs: 1,
      banner: [
        {
          img: require("../../../src/assets/index/0220623112142.png"),
        },
      ],
      threewomen: [
        {
          img: require("../../../src/assets/logoimgs/hair-loss-thinning-hair.jpg"),
          name: "HAIR LOSS & THINNING HAIR",
        },
        {
          img: require("../../../src/assets/logoimgs/hair-toppers@2x.jpg"),
          name: "HAIR TOPPERS",
        },
        {
          img: require("../../../src/assets/logoimgs/human-hair-wigs@2x.jpg"),
          name: "HUMAN HAIR WIGS",
        },
      ],
      fourimg: [
        {
          img: require("../../../src/assets/logoimgs/1-lace-front-full-lace-wigs.jpg"),
          name: "HAIR LOSS & THINNING HAIR",
        },
        {
          img: require("../../../src/assets/logoimgs/1-lace-front-full-lace-wigs.jpg"),
          name: "HAIR LOSS & THINNING HAIR",
        },
        {
          img: require("../../../src/assets/logoimgs/1-lace-front-full-lace-wigs.jpg"),
          name: "HAIR LOSS & THINNING HAIR",
        },
        {
          img: require("../../../src/assets/logoimgs/1-lace-front-full-lace-wigs.jpg"),
          name: "HAIR LOSS & THINNING HAIR",
        },
      ],
      wigs: [
        {
          h4: "WIG WHOLESALE PRODUCTS DISPLAY",
          title: "TOP SALES",
          title2: "NEW ARRIVALS",
        },
      ],
      wigboxs: [
        {
          img: require("../../assets/logoimgs/Free-Hair-Curls-Long-Curly-Hair-Topper.jpg"),
          imgs: require("../../assets/logoimgs/STL6x6.5-Silk-Top-Lace-Front-Topper.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Free-Hair-Curls-Long-Curly-Hair-Topper.jpg"),
          imgs: require("../../assets/logoimgs/STL6x6.5-Silk-Top-Lace-Front-Topper.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Free-Hair-Curls-Long-Curly-Hair-Topper.jpg"),
          imgs: require("../../assets/logoimgs/STL6x6.5-Silk-Top-Lace-Front-Topper.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Free-Hair-Curls-Long-Curly-Hair-Topper.jpg"),
          imgs: require("../../assets/logoimgs/STL6x6.5-Silk-Top-Lace-Front-Topper.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Free-Hair-Curls-Long-Curly-Hair-Topper.jpg"),
          imgs: require("../../assets/logoimgs/STL6x6.5-Silk-Top-Lace-Front-Topper.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Free-Hair-Curls-Long-Curly-Hair-Topper.jpg"),
          imgs: require("../../assets/logoimgs/STL6x6.5-Silk-Top-Lace-Front-Topper.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Free-Hair-Curls-Long-Curly-Hair-Topper.jpg"),
          imgs: require("../../assets/logoimgs/STL6x6.5-Silk-Top-Lace-Front-Topper.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Free-Hair-Curls-Long-Curly-Hair-Topper.jpg"),
          imgs: require("../../assets/logoimgs/STL6x6.5-Silk-Top-Lace-Front-Topper.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
      ],
      wigboxs2: [
        {
          img: require("../../assets/logoimgs/Headband-Wigs-with-Highligh.jpg"),
          imgs: require("../../assets/logoimgs/Full-Lace-Wig18-Straight-Natural-Color.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Headband-Wigs-with-Highligh.jpg"),
          imgs: require("../../assets/logoimgs/Full-Lace-Wig18-Straight-Natural-Color.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Headband-Wigs-with-Highligh.jpg"),
          imgs: require("../../assets/logoimgs/Full-Lace-Wig18-Straight-Natural-Color.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Headband-Wigs-with-Highligh.jpg"),
          imgs: require("../../assets/logoimgs/Full-Lace-Wig18-Straight-Natural-Color.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Headband-Wigs-with-Highligh.jpg"),
          imgs: require("../../assets/logoimgs/Full-Lace-Wig18-Straight-Natural-Color.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Headband-Wigs-with-Highligh.jpg"),
          imgs: require("../../assets/logoimgs/Full-Lace-Wig18-Straight-Natural-Color.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Headband-Wigs-with-Highligh.jpg"),
          imgs: require("../../assets/logoimgs/Full-Lace-Wig18-Straight-Natural-Color.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
        {
          img: require("../../assets/logoimgs/Headband-Wigs-with-Highligh.jpg"),
          imgs: require("../../assets/logoimgs/Full-Lace-Wig18-Straight-Natural-Color.jpg"),
          content: "IN6*6 Stock Silk Mono Blonde Hair Topper Wholesale",
          but1: "Quick View",
          but2: "Login to view prices",
        },
      ],
      beauful: [{ img: require("../../assets/index/20220623150758.png") }],
      custom: [
        {
          h3: "CUSTOM WIG WHOLESALE SERVICE",
          content:
            "CUSTOM WIG WHOLESALE SERVICEAs a respected hair wig manufacturer and wig wholesaler that’s dedicated to wholesale wigs worldwide, China wig supplier New Times Hair takes pride in producing top-notch custom hair wigs for our partners. As opposed to stock wigs for women, custom hair wigs are a great option if you have customers who have their own needs and expectations for their hair wigs that stock models can’t fulfill. In that case, hair wig customization is the solution to win their business. Despite a higher cost and longer delivery time, custom hair wigs are becoming an increasingly popular and common option among wearers in many countries. People’s needs for their hair wig products are becoming more diverse and demanding. Having manufactured tens of thousands of women’s custom hair wigs that are endorsed by our clients’ customers, China wig wholesaler New Times Hair always puts its reputation and customer satisfaction first. All of our custom hair wigs are carefully handcrafted by the most experienced workers with the finest materials. This allows us to help our partners gain the trust and recognition from their customers using our superior hair systems. To customize the right hair wigs for your customers, it’s very important to have a good knowledge of what their true needs are concerning different aspects of their wigs. We can help with that by providing a list of questions to ask for custom orders.",
          img: require("../../assets/logoimgs/Womens-customization-img.jpg"),
          button: "COUSTOMIZE NOW",
        },
      ],
      china: [
        {
          h3: "CHINA WIG SUPPLIER | WIG WHOLESALE SERVICE",
          name: "New times hair, a professional leading wig wholesaler and wig supplier, can wholesale top inventory and custom women's wigs from China at the lowest price",
        },
      ],
      chinaimg: [
        {
          img: require("../../assets/index/20220623154225.png"),
        },
        {
          img: require("../../assets/index/20220623154225.png"),
        },
        {
          img: require("../../assets/index/20220623154225.png"),
        },
      ],
      clores: [
        {
          h3: "Who Are We",
          content:
            "As one of the most respected wig wholesalers in the world,we are able to wholesale first-class stock and customwomen's wigs from China at the lowest direct factory prices.ln the last lO years, wig wholesaler New Time Hair haspartnered with a great number of wig wholesale clients fromover 1OO countries, whose wig wholesale businesses havegrown thanks to our lasting partnerships. We have a largevariety of hair wigs available in different colors and sizes. Soyoull have no problem finding the right ones for yourcustomers. As a wig wholesaler and one of the largest wigsuppliers in China, we offers wholesale wigs service indifferent hair colors and base sizes.",
        },
      ],
      Whyjoin: [
        {
          h3: "Join us as an exclusive wholesaler",
          h32: "Why join?",
          h33: "Get the best wholesale prices",
          h34: "Special offer for sample orders",
          h35: "Access to product experts",
          button: "Apply now",
        },
      ],
      listphoto: [
        {
          img: require("../../../src/assets/index/women-wig-review-1.jpg"),
          imgs: require("../../../src/assets/index/women-wig-review-3.jpg"),
          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan11111111111",
        },
        {
          img: require("../../../src/assets/index/sdfbefore.jpg"),
          imgs: require("../../../src/assets/index/mones.jpg"),

          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan222222222222222222222",
        },
        {
          img: require("../../../src/assets/index/newtimeshair-review-2.jpg"),
          imgs: require("../../../src/assets/index/women-wig-review-3.jpg"),

          content:
            "Choosing a trustorthy and long-term supplier is a tricky thing for me before until lmet New Timezs Hair. I still remembered my first impression of the bulk ordersfrom you, they are just fabulous and l am really pleased! The logistics, patient andconsiderate customer service, and so on.Lucky to meet you all along to boost mybusiness in futurel",
          name: "Ryan3333333333333",
        },
      ],
      listli: [
        { li: " FREE Hair Systems and Exciting Coupon Codes " },
        { li: " Exclusive WHOLESALE Discounts " },
        { li: "  Business Start-Up Guidance " },
        { li: "  Gain greater Social Media Exposure " },
      ],
      list: [
        { li: "Get the best wholesale prices" },
        { li: "Special offer for sample orders" },
        { li: "Access to product experts" },
      ],
      Become_dis: [
        {
          img1: require("../../../src/assets/index/20220623164237.png"),
          dish2: "Become a Brand Ambassador",
          p1: "And most importantly – be a part of a supportive and uplifting community of hair replacement specialists worldwide!",
          p2: "It’s a rare opportunity, so don’t hesitate to get in before your competition!",
          button: "Apply now",
        },
      ],
      dis2: [
        {
          img2: require("../../../src/assets/index/Album3.jpg"),
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
      questcountry: [],
      questioninputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      Whyjoinscountry: [],
      Whyjoininputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
    this.questcountry = this.$store.state.countryJson;
    this.Whyjoinscountry = this.$store.state.countryJson;
  },
  methods: {
    checkoutType(index) {
      console.log(index);
      this.tabs = index;
      this.typePopup = false;
      this.type_txt = this.typeList[index].title;
    },
    // gethelp提交按钮
    gethelp() {},
    submit() {},
    submits() {},
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    //TOP SALES button跳转页面需要带id
    //修改
    goview() {
      this.$router.push({
        path: "../../Viewdetail?id=" + "ids",
      });
    },
    //custom 按钮
    customsubmit() {},
    Whyjoinsubmit() {},
  },
};
</script> 
<style lang="less" scoped>
.WOMEN {
  width: 100%;
  .women {
    .banner {
      img {
        width: 100%;
      }
    }
    .threewomen {
      display: flex;
      justify-content: space-between;
      text-align: center;

      div {
        width: 30%;
        img {
          width: 90%;
        }
      }
      div:hover {
        transform: scale(1.2);
        cursor: pointer; //变小手
        transition: all 0.7s;
      }
    }
    .fourwomen {
      display: flex;
      justify-content: space-between;
      text-align: center;
      div {
        width: 22%;
        img {
          width: 100%;
        }
      }
      div:hover {
        transform: scale(1.2);
        cursor: pointer; //变小手
        transition: all 0.7s;
      }
    }
  }
  .black {
    background-color: black;
    height: 150px;
    line-height: 150px;
    opacity: 0.6;
    display: flex;
    justify-content: space-around;
    p {
      font-weight: 600;
      font-size: 22px;
      color: #fff;
    }
    button {
      background-color: black;
      //   opacity: 0.6;
      height: 50px;
      line-height: 50px;
      margin-top: 50px;
      border-radius: 25px;
      width: 15%;
      color: #fff;
      border: 1px solid #fff;
    }
    button:hover {
      transform: scale(1.1);
      cursor: pointer; //变小手
      transition: all 0.7s;
    }
  }
  .oldwoman {
    margin: 40px 0;
    background: url("../../assets/index/oldwoman.png");
    height: 400px;
    width: 100%;
    img {
      width: 7%;
      margin-left: 45%;
      margin-top: 130px;
    }
  }
  .wig {
    text-align: center;
    margin: 40px 0;
    h4 {
      color: #002548;
    }
    .womensmenu {
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;

      span {
        width: 170px;
        margin-top: 50px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        background-color: #fff;
        color: #9f9999;
        border: 1px soild #fff;
        border-radius: 8px 8px;
        font-size: 23px;
        font-weight: 600;
      }
      .typeActive {
        // background-color: #FBB034;
        color: #fbb034;
      }
    }
    .tabs1 {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      text-align: center;
      .wigboxs {
        width: 22%;
        margin: 20px 0;
        img {
          width: 100%;
        }
        .img2 {
          display: none;
        }
      }
      .wigboxs:hover {
        .img1 {
          display: none;
        }
        .img2 {
          display: block;
        }
      }
      button {
        width: 100%;
        padding: 10px 3%;
        border: 1px solid #002548;
        background-color: #002548;
        color: #fff;
        margin: 5px 0;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }
    .tabs2 {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      text-align: center;
      .wigboxs {
        width: 22%;
        margin: 20px 0;
        img {
          width: 100%;
        }
        .img2 {
          display: none;
        }
      }
      .wigboxs:hover {
        .img1 {
          display: none;
        }
        .img2 {
          display: block;
        }
      }
      button {
        width: 100%;
        padding: 10px 3%;
        border: 1px solid #002548;
        background-color: #002548;
        color: #fff;
        margin: 5px 0;
      }
      button:hover {
        background-color: #f29d35;
        border: 1px solid #f29d35;
      }
    }
  }
  .CUSTOMwig {
    width: 75%;
    margin: 40px auto;
    .CUSTO {
      display: flex;
      justify-content: space-between;
      background-color: #f2f2f2;
      .left {
        text-align: left;
        padding: 30px 2%;
        width: 70%;
        color: #8c7e85;
        line-height: 26px;
        button {
          background-color: #f29d35;
          border: 1px solid #f29d35;
          padding: 5px 2%;
          color: #fff;
          text-align: center;
          margin-left: 35%;
        }
        button:hover {
          background-color: #fff;
          border: 1px solid #fff;
          color: #000;
        }
      }
      .right {
        width: 23%;
        img {
          width: 100%;
        }
      }
    }
  }
  .CHINA {
    width: 100%;
    margin: 60px 0;
    .chinaimg {
      display: flex;
      justify-content: space-around;
    }
    .CHINA_content {
      width: 75%;
      margin: 0px auto;
      .chinafont {
        // color: #fff;
        text-align: center;
        padding: 30px 0;
      }
      .chinas {
        width: 30%;
        position: relative;
        img {
          max-width: 438.99px;
          width: 100%;
        }
        .colors {
          display: flex;
          justify-content: space-around;
          display: none;
          background-color: #796977;
          height: 386.55px;
          max-width: 392.82px;
          width: 100%;
          position: absolute;
          top: 0;
          text-align: center;
          h3 {
            padding-top: 30px;
          }
          p {
            font-size: 13px;
            padding: 15px 3%;
          }
        }
      }
      .chinas:hover {
        //   transition: all 0.7s;
        .colors {
          display: block;
          cursor: pointer; //变小手
          color: #fff;
        }
      }
    }
  }
  .Whyjoins {
    .div1 {
      padding-top: 80px;
    }
    color: #002548;
    margin: 40px 0;
    width: 100%;
    background: url("../../assets/index/20220623160936.png");
    height: 520px;
    width: 100%;
    text-align: center;
    h3 {
      padding: 10px 0;
    }
    button {
      background-color: #012340;
      border: 1px solid #012340;
      color: #fff;
      padding: 10px 3%;
      font-size: 18px;
      border-radius: 25px;
    }
    button:hover {
      background-color: #f29d35;
      border: 1px solid #f29d35;
    }
  }
  .WHOLESALE {
    width: 100%;
    text-align: center;
    .el-carousel__item h3 {
      // color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 600px;
      margin: 0;
    }
    .alwasing {
      width: 90%;
      padding-left: 10%;
      display: flex;
      justify-content: space-around;
      .always {
        width: 50%;
        margin: 0 auto;
        text-align: center;
        .always_box {
          img {
            width: 90%;
          }
          p {
            color: #000;
            width: 93%;
            z-index: 999;
            text-align: left;
            padding-left: 6%;
          }
        }
      }
    }
  }
  .BECOMES {
    width: 75%;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    .Become {
      // height: 500px;
      width: 49%;
      margin-top: 20px;
      // background: url();
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // color: #012340;
      .Become_dis {
        position: relative;
        .img1 {
          height: 500px;
          width: 100%;
        }
      }
      .Become_bg {
        position: absolute;
        top: 25px;
        left: 5%;
        background-color: #fff;
        opacity: 0.7;
        width: 90%;
        padding-top: 50px;
        button {
          border: none;
          border: 1px solid #0a2840;
          background-color: #0a2840;
          color: #fff;
          padding: 8px 5%;
          margin: 20px 0;
          margin-left: 37%;
          text-align: center;
          border-radius: 25px;
          opacity: 1;
        }
        button:hover {
          background-color: #f29d35;
          border: 1px solid #f29d35;
          border-radius: 18px;
        }
        h2 {
          text-align: center;
          padding: 20px 0;
          opacity: 1;
        }
      }
    }
  }
  .FAQ {
    widows: 75%;
    margin: 0 auto;
    h2 {
      padding: 20px 0;
      text-align: center;
    }
    button {
      color: #000;
      font-weight: 600;
    }
  }
  .CONTACTs {
    display: none;
  }
  .CONTACT {
    text-align: center;
    margin: 50px 0;

    padding: 50px 0;
    width: 80%;
    margin: 0 auto;
    h2 {
      font-weight: 600;
    }
    .inputs {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      input {
        width: 45%;
        height: 50px;
        margin: 10px 0;
        border: none;
        border: 1px solid #cfd5db;
      }
    }
    textarea {
      border: none;
      border: 1px solid #cfd5db;
      width: 95%;
      height: 200px;
    }
    button {
      margin-top: 30px;
      background-color: #304e6a;
      border: 1px solid #304e6a;
      padding: 10px 1.5%;
      border-radius: 5px;
      color: #fff;
    }
    button:hover {
      background-color: #f9bd5a;
      border: 1px solid #f9bd5a;
    }
  }
  .top {
    img {
      width: 3%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 50px;
    }
  }
  .tops {
    display: none;
  }
  .modal-dialog {
    max-width: 900px;
    margin: 0 auto;
  }
  .modal-headers,
  .modal-header {
    color: #fff;
    height: 230px;
    width: 100%;
    background: url("../../../src/assets/index/20220624091530.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    h4 {
      padding-top: 40px;
    }
  }
}
</style>